
import { defineComponent } from "vue";

import CustomButton from "@/components/CustomButton.vue";
import Avatar from "@/components/AvatarImage.vue";
import CustomInput from "@/components/CustomInput.vue";
import InformationTemplate from "@/components/InformationTemplate.vue";
import { MeModule } from "@/store/modules/me";
import { updateUser } from "@/services/userService";

export default defineComponent({
  components: { Avatar, CustomButton, CustomInput, InformationTemplate },
  data() {
    return {
      username: "",
      tag: "",
      banner: null,
      avatar: null,
      errors: {} as any,
    };
  },
  methods: {
    reset() {
      this.username = this.currentUser.username || "";
      this.tag = this.currentUser.tag || "";
      this.avatar = null;
      this.banner = null;
    },
    onNext() {
      if (!Object.values(this.changedValues).length) {
        this.$emit("onAction", true);
        return;
      }
      this.errors = {};
      this.save();
    },
    save() {
      updateUser(this.changedValues, this.$socket.id)
        .then((res) => {
          MeModule.UpdateUser(res);
          this.reset();
          this.$emit("onAction", true);
        })
        .catch(async (err) => {
          this.$emit("onAction", false);
          if (!err.response) {
            this.errors["other"] = this.$t("could-not-connect-to-server");
            return;
          }
          const { errors, message } = await err.response.json();
          if (message) {
            this.errors["other"] = message;
            return;
          }
          const knownErrs = ["username", "tag"];
          for (let i = 0; i < errors.length; i++) {
            const error = errors[i];
            if (!knownErrs.includes(error.param)) {
              this.errors["other"] = error.msg;
              continue;
            }
            this.errors[error.param] = error.msg;
          }
        });
    },
    avatarChange(event: any) {
      const file: File = event.target.files[0];
      event.target.value = "";
      if (!file) return;
      const reader = new FileReader();
      reader.onloadend = (event) => {
        this.avatar = (event.target?.result as any) || null;
      };
      reader.readAsDataURL(file);
    },
    bannerChange(event: any) {
      const file: File = event.target.files[0];
      event.target.value = "";
      if (!file) return;
      const reader = new FileReader();
      reader.onloadend = (event) => {
        this.banner = (event.target?.result as any) || null;
      };
      reader.readAsDataURL(file);
    },
  },

  watch: {
    connected: {
      immediate: true,
      handler() {
        this.reset();
      },
    },
  },
  computed: {
    changedValues() {
      let changed: any = {};
      if (this.avatar) changed.avatar = this.avatar;
      if (this.banner) changed.banner = this.banner;
      if (this.username !== this.currentUser.username)
        changed.username = this.username;
      if (this.tag !== this.currentUser.tag) changed.tag = this.tag;
      return changed;
    },
    bannerImageUrl(): any {
      if (this.banner) return this.banner;
      if (!this.currentUser.banner) return null;
      return process.env.VUE_APP_NERTIVIA_CDN + this.currentUser.banner;
    },
    currentUser() {
      return MeModule.user;
    },
    connected() {
      return MeModule.connected;
    },
  },
});
